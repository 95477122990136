import { ClassData } from '@models/class-data';

export class GlobalSettings {
  id: number;
  registerWithoutEmail: boolean;

  constructor(json: ClassData<GlobalSettings>) {
    this.id = +json.id;
    this.registerWithoutEmail = json.registerWithoutEmail;
  }

  isRegisterWithoutEmail() {
    return this.registerWithoutEmail;
  }
}
